import React from "react";
import "../../App.css";
import "../pages/EducationHub.css";

export default function EducationHub() {
  return (
    <div className="educationHub">
      <hr style={{ position: "relative", top: "-8px" }} />

      <article>
        <h1
          style={{
            display: "block",
            alignItems: "center",
            color: "#2e2e2e",
            maxWidth: "550px",
            margin: "0 auto",
            textAlign: "justify",
            marginTop: "40px",
            marginBottom: "25px",
            textAlignLast: "center",
            fontSize: "35px",
            fontWeight: "700",
            lineHeight: "1.2222222222222223",
          }}
        >
          A Formal Analysis of a Case-Control Study That Is Focused on the
          Sleeping Behaviors of Children Diagnosed with Smith-Magenis Syndrome
          (SMS)
        </h1>

        <h2
          style={{
            color: "#737373",
            textAlign: "center",
            maxWidth: "550px",
            margin: "0 auto",
            marginBottom: "30px",
          }}
        >
          <center>
            A close look at a study analyzing how a rare genetic condition
            limits melatonin production and causes sleep abnormalities in
            clinically diagnosed children with prospect on how to remedy the
            issue
          </center>
        </h2>

        <div className="author-info">
          <img
            src="images/bypicture.jpg"
            alt="Marisa"
            className="author-image"
          />
          <div className="author-text">
            <div className="byline">by Marisa</div>
            <div className="published-date">Published on March 10, 2023</div>
          </div>
        </div>

        <img
          src="images/dna.jpeg"
          alt="DNA"
          style={{
            display: "block",
            margin: "0 auto",
            width: "90%",
            paddingBottom: "30px",
          }}
        />

        <p>
          When I was five years old my aunt had her first son. When he was born,
          he had a plethora of minor health issues and as he grew up, his
          parents and family noticed that he had behavioral differences and
          struggled with important aspects of life such as sleeping, cognition
          and health problems. My aunt brought him to the hospital frequently
          until they finally diagnosed him with Smith-Magenis Syndrome (SMS).
          Since that day they have been on the hunt for various ways he can be
          treated to alleviate the difficulties of the condition. When I was
          fourteen my sister and I held a bake sale concerning PRISMS, the
          foundation dedicated to furthering research on Smith-Magenis syndrome.
          It is an American-based foundation as there are no Smith-Magenis
          foundations in Canada. It is a condition that is deemed very rare, and
          therefore it is hard to find proper treatment and support, especially
          in Canada. One thing I always noticed is that my cousin does not sleep
          very well. He often wakes up throughout the night and won’t sleep for
          more than a few hours, frequently waking up every day at 5 am. I have
          always been curious about furthering my knowledge on the condition and
          ways to help my cousin, therefore I will be looking at a case-control
          study that looks at the sleeping habits of children diagnosed with
          Smith-Magenis Syndrome.
        </p>

        <p> What Is Smith-Magenis Syndrome?</p>
        <p> Smith-Magenis Syndrome is a complex genetic condition that causes intellectual disability, behavioral issues and
      other health issues. It is caused by a chromosomal deletion or functionality problem on chromosome 17p11.2 (NORD -
      National Organization for Rare Disorders, 2017). Chromosome 17 varies in characteristics from the others as it has
      the second highest density out of all the chromosomes. This is because chromosome 17 is rich in protein-coding
      genes. Because of chromosome 17’s intricate rearrangement and duplication structure it predisposes it to
      non-allelic homologous recombination (NAHR) which leads to the DNA rearrangements that cause several well-studied
      microdeletion disorders like Smith-Magenis Syndrome (Zody et al., 2006). Since this chromosome is either deleted
      or non-functional, it does not code for the important proteins that promote human cell regulation and development.
      The RAI1 gene is the exact protein associated with Smith-Magenis Syndrome. Since there is an insufficient
      production of RAI1, also known as retinol acid-induced 1, it leads to defects that are the cause of the
      behavioral, temperamental, emotional and clinical issues seen in individuals with the condition (Falco et al.,
      2017). Since this condition affects chromosome 17, it is haploinsufficient in individuals with Smith-Magenis
      Syndrome. Chromosome 17 is an essential part of the transcription of the circadian locomotor output cycles kaput,
      commonly known as the CLOCK gene (Trickett et al., 2020). The CLOCK gene is responsible for the regulation of the
      circadian rhythm, therefore causing the characterizing of sleep abnormalities in individuals with Smith-Magenis
      Syndrome.

    </p>
    <p> What Data Were They Looking For?

    </p>

    <p> This study aimed to use actigraphy data to compare the temporal analysis of the brain activity of children with
      SMS and developing children without the condition (Trickett et al., 2020). This study was formulated on the
      foundational knowledge of the relationship between Smith-Magenis syndrome and circadian rhythm. A part of the
      condition is that it creates sleep abnormalities and irregular circadian rhythm. This study uses Smith-Magenis
      diagnosed children and age-matched control children to compare their circadian rhythm patterns and the impact of
      day-time sleepiness and inefficient sleep patterns on a child's brain. An actigraphy is a type of device that can
      be easily worn by children for a certain period to measure factors such as the time one falls asleep, the time one
      wakes up, the duration it takes one to fall asleep, the duration of one's total sleep and the amount of waking
      hours one experiences after falling asleep beforehand (Pacheco & Singh, 2021). Temporal analysis is conducted by
      comparing the data collected by the actigraphy to analyze the different effects of sleepiness and the duration of
      sleep concerning behavior over different periods in a day.

    </p>

    <p>What Factors Did They Focus On And Find?

    </p>
    <p>In this study, they restricted it to children that ranged from four to fifteen years old. They recruited 26
      children to participate in this study, yet six children could not handle using the actigraphy watch for more than
      a few nights, therefore this is a case-control study that focuses on twenty children, ages four to fifteen
      clinically diagnosed with Smith-Magenis Syndrome. They then age-matched 45 controls to be compared to the
      exposed/diagnosed group of children. Their first order of business was to administer a questionnaire to all of the
      parents of the children in this study to be filled out. This questionnaire included questions about family income
      and medication use, focusing on melatonin supplements. They found no significant differences between families with
      different incomes as well as children who took melatonin supplements to be significant factors in this study. When
      analyzing the actigraphy data of the exposed/diagnosed group and comparing it to the control group, the children
      with Smith-Magenis Syndrome had far earlier morning wake times, shorter sleep duration and a large number of
      nightly waking periods than compared to the control children. Out of the children diagnosed with Smith-Magenis
      Syndrome, 95% of the children partake in diurnal napping (daytime napping) while only 10% of the control children
      took diurnal naps. The mean for the exact time at which the children with Smith-Magenis Syndrome took their naps
      had a mean of 90% across the duration of study time, meaning the time varied among the days. They also found a
      negative relationship between total sleep time and diurnal naps which may be why they also saw that children with
      Smith-Magenis Syndrome tended to go to bed earlier than the control group. When looking at daytime sleepiness in
      children, 76.5% of the children with Smith-Magenis Syndrome suffered from this compared to zero percent of the
      control group. The sleeping tendencies of the children in the control group varied depending on their age, while
      the children with Smith-Magenis Syndrome had no significant correlation in regards to a relationship between age
      and the duration of sleep, yet the time at which individuals went to bed became later with the increasing of age
      amongst both groups.

    </p>

    <p> What Relationships Did This Case-Control Study Lead Them To Find?

    </p>
    <p>In this study, multiple factors associated with sleep were found to be very impactful. Descriptive statistics
      were used to measure the significance of these findings. A p-value of >0.05 was deemed significant, with an alpha
      value set at less than 0.01 to decrease the chance of type I error among the variables. Attached below is a mixed
      linear model taken directly from the study, modeling the relationships they found and how they work together in
      the children with Smith-Magenis Syndrome when compared to the control group.

    </p>

    <p> <u>Figure 3. From Trickett et al., 2020 Study Showing The Relationships Found Between Sleeping And Behaviour In
        Children With Diagnosed Smith-Magenis Syndrome </u>

    </p>


    <figure>
      <img src="images/linear-model.jpg" alt="Mixed linear model displaying the relationships between daytime sleepiness, total sleep time, daytime napping duration and daytime sleepiness as well as impulsivity, irritability and overactivity" />
      <figcaption>Figure 3: A mixed linear model displaying the relationships between daytime sleepiness, total sleep
        time, daytime napping duration and daytime sleepiness as well as impulsivity, irritability and overactivity from
        Trickett et al., 2020 (https://doi.org/10.1093/sleep/zsz260).</figcaption>
    </figure>

    <p>This model demonstrates the interpersonal relationships between the findings from this study and their
      significance. This model shows how longer nap time duration caused a shorter duration of sleeping from the start
      of bedtime and shorter sleep during the night added to the duration of nap time the next day. Some relationships
      were found to be irreversible and only worked forwards. With this, daytime sleepiness did not predict the total
      sleep time of the children that night and total sleep time did not work in predicting daytime sleepiness in the
      individuals as well. The duration of daily naps was found to have an impact on the sleepiness of the children and
      played into behavioral problems. Higher levels of sleepiness were found to be directly correlated to the
      irritability and impulsivity of the children.

    </p>

    <p> What Does This Information Tell Us And How Can We Apply It?

    </p>

    <p> Using the knowledge on Chromosome 17 and its effect on circadian rhythm, these researchers compared factors that
      have never been compared in Smith-Magenis Syndrome individuals. The main overall findings of this study were how
      interconnected all aspects of the condition are regarding sleep. The behavioral problems of individuals with
      Smith-Magenis Syndrome worsened with the decrease in sleep, leading to daytime sleepiness. The scientists of this
      study suggested that proper hygiene before bed and creating a routine may have a greater impact on the amount of
      nocturnal sleep an individual with Smith-Magenis may get in a night. Another method they suggested was to use
      morning beta-blockers to minimize melatonin production during waking hours. These are two optimal suggestions as
      when combined, they can help increase nocturnal sleep in individuals, which is what leads to daytime sleepiness as
      well as eliminate melatonin production to fix the circadian rhythm, eliminating daytime sleepiness. These two
      factors together would benefit individuals significantly, correcting sleeping patterns and in turn helping with
      behavioral issues.

    </p>

    <p> Conclusion And Take Aways

    </p>


    <p> Since I have personal experience with this condition, analyzing this case-control study was very insightful. My
      cousin suffers from these sleep problems, and the idea of blocking melatonin production during the day seems like
      a very integral concept. To add to their suggestions, I would also suggest a controlled home environment for
      individuals with Smith-Magenis Syndrome. A relaxed, calm environment and the use of these tools could really
      benefit these children suffering from this condition. After reading the information about the genetics and issues
      with Chromosome 17, RAI1 and the CLOCK gene, there is hope that modern technology could help remedy individuals.
      Possibly in the case of individuals with a damaged chromosome 17 and not entirely missing, modern gene editing
      tools (ex. CRISPR-Cas9) could be developed and used to promote the regulation of the proteins associated with it.
      There is still a lot of research that needs to go into alleviating these individuals, but with the use of modern
      medicine like gene editing tools and furthering the research of these rare conditions, there is hope for
      treatments to help these patients.

    </p>
        <p>
          <b>References</b>
        </p>
        <p>
          NORD - National Organization for Rare Disorders. (2017, June 23).
          Smith Magenis Syndrome. NORD (National Organization for Rare
          Disorders).
          https://rarediseases.org/rare-diseases/smith-magenis-syndrome/
        </p>
        <p>
          Zody, M. C., Garber, M., Adams, D. J., Sharpe, T., Harrow, J., Lupski,
          J. R., Nicholson, C., Searle, S. M., Wilming, L., Young, S. K.,
          Abouelleil, A., Allen, N. R., Bi, W., Bloom, T., Borowsky, M. L.,
          Bugalter, B. E., Butler, J., Chang, J. L., Chen, C. K., Cook, A., …
          Nusbaum, C. (2006). DNA sequence of human chromosome 17 and analysis
          of rearrangement in the human lineage. Nature, 440(7087), 1045–1049.
          https://doi.org/10.1038/nature04689
        </p>
        <p>
          Falco, M., Amabile, S., & Acquaviva, F. (2017, November 3). rai1 gene
          mutations: Mechanisms of Smith-Magenis Syndrome. The application of
          clinical genetics. Retrieved November 14, 2022, from
          https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5680963/
        </p>
        <p>
          Trickett, J., Oliver, C., Heald, M., Denyer, H., Surtees, A.,
          Clarkson, E., Gringras, P., Richards, C., Sleep in children with
          Smith–Magenis syndrome: a case–control actigraphy study, Sleep, Volume
          43, Issue 4, April 2020, zsz260, https://doi.org/10.1093/sleep/zsz260
        </p>
        <p>
          Pacheco, D., & Singh, A. (2021, October 1). How Is Actigraphy Used to
          Evaluate Sleep? Sleep Foundation.
          https://www.sleepfoundation.org/sleep-studies/actigraphy
        </p>
      </article>
    </div>
  );
}
