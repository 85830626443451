import React from "react";
import "../../App.css";
import "./Podcasts.css"; // Create a separate CSS file for styling

export default function Podcasts() {
  return (
    <div className="podcast-container">
      <h1 className="podcast-title">Beyond The Diagnosis Episodes</h1>
      <div className="podcast-iframe">
        <iframe
          title="Podcast Episode 1"
          src="https://open.spotify.com/embed/episode/1gGaMVERDCTp35eYa0YMe4?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
      <div className="podcast-iframe">
        <iframe
          title="Podcast Episode 2"
          src="https://open.spotify.com/embed/episode/6hItHcpWbD3M2pHBp6ZRS9?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
      <div className="podcast-iframe">
        <iframe
          title="Podcast Episode 3"
          src="https://open.spotify.com/embed/episode/0mA8iwGKqVzlVeGjEqNtNS?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
      <div className="podcast-iframe">
        <iframe
          title="Podcast Episode 4"
          src="https://open.spotify.com/embed/episode/6foe0oy0GsMTid8nPvFgYJ?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}
