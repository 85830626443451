import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css";
import Home from "./components/pages/Home";
import TooRareStories from "./components/pages/TooRareStories";
import Podcasts from "./components/pages/Podcasts";
import EducationHub from "./components/pages/EducationHub";
import Posters from "./components/pages/Posters";
import ContactUs from "./components/pages/ContactUs";
import Footer from "./components/Footer";
import Team from "./components/pages/Team";


function App() {
  return (
    <>
        <Router>
        <Navbar />
        <Routes>
          {/* <Route path="/" exact/>  */}
          <Route path='/' exact element={ <Home />}></Route>
          <Route path='/TooRareStories' element={<TooRareStories/>} />
          <Route path='/Podcasts' element={<Podcasts/>} />
          <Route path='/EducationHub' element={<EducationHub/>} />
          <Route path='/Posters' element={<Posters/>} />
          <Route path='/ContactUs' element={<ContactUs/>} />
          <Route path='Team' element={<Team/>} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;