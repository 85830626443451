import React from "react";
import "./AboutUs.css";

function AboutUs() {
  return (
    <div className="AboutUs">
      <h1>About us</h1>
      <br />
      <br />
      <h2
        style={{
          color: "#5e6a71",
          fontWeight: 100,
          paddingRight: 0,
          paddingLeft: 0,
          margin: 0,
          fontFamily: "Arial"
        }}
      >
        <center>
          {" "}
          The TMU Rare Diseases Club is a non-profit organization dedicated 
          to raising awareness of rare diseases within the TMU community. 
          Rare diseases are serious, chronic conditions affecting 1 in 12 Canadians. 
          In addition to providing a platform for discussion and activities within our Science community, 
          we hope to inspire TMU science students to support those with rare diseases and 
          increase public awareness of rare diseases as a whole.
          {/* <br />
          <br />
          This student group is working to educate and promote awareness about 
          rare disorders in Canada among medical professionals, the TMU community, 
          and faculty members through research, fundraising, group discussions, etc. 
          We will collaborate with organizations like CORD (Canadian Organization for Rare Disorders) 
          and the NORD Student Chapter, which share similar objectives for rare disease development. */}
        </center>
      </h2>
    </div>
  );
}

export default AboutUs;
